<template>
  <CheckoutLayout :step="2">
    <div v-if="isOrderDefined || itemsInCart > 0" class="row">
      <div class="col-12">
        <h2 class="checkout__card-title checkout__card-title--left">
          Wprowadź dane
        </h2>
      </div>

      <div class="col-12 order-xxl-2 col-xxl-8 col-xxxl-7">
        <div class="checkout__form">
          <div class="checkout__form-input">
            <UserAttributeFormInput name="firstName" />
          </div>

          <div class="checkout__form-input">
            <UserAttributeFormInput name="lastName" />
          </div>

          <p v-show="paymentType !== 'deferred'" class="checkout__toggle">
            <Toggle
              id="checkout-invoice"
              v-model="invoice"
              name="checkout-invoice"
              type="checkbox"
              @change="invoiceOnChange"
            >
              Chcę fakturę
            </Toggle>
          </p>

          <div
            :class="{
              'checkout__invoice--active': isInvoiceDataRequired,
            }"
            class="checkout__invoice"
          >
            <div class="checkout__form-input">
              <UserAttributeFormInput
                name="vatNumber"
                :disabled="isCompanyDataDisabled"
              />
            </div>

            <div class="checkout__form-input">
              <UserAttributeFormInput
                name="companyName"
                :disabled="isCompanyDataDisabled"
              />
            </div>

            <div class="checkout__form-input">
              <UserAttributeFormInput
                :disabled="isCompanyDataDisabled"
                name="street"
              />
            </div>

            <div class="checkout__form-input">
              <UserAttributeFormInput
                name="city"
                :disabled="isCompanyDataDisabled"
              />
            </div>

            <div class="checkout__form-input">
              <UserAttributeFormInput
                name="postcode"
                :disabled="isCompanyDataDisabled"
              />
            </div>

            <div class="checkout__form-input">
              <UserAttributeFormInput
                name="phoneNumber"
                :disabled="isCompanyDataDisabled"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 order-3 col-xxl-3 offset-xxl-1 offset-xxxl-2">
        <div class="checkout__payment-box checkout__payment-box--payment">
          <div class="payment-box">
            <p class="payment-box__header">
              Twoje zamówienie:
              <span class="payment-box__count"
                >(Liczba zdjęć: {{ itemsInCart }})</span
              >
            </p>

            <div class="payment-box__content">
              <p class="payment-box__text">
                Kwota do zapłaty:
                <span class="payment-box__price">{{ cartTotalFormatted }}</span>
              </p>

              <p class="payment-box__text">
                Wybierz sposób płatności:
              </p>
              <ul class="payment-box__payment-methods">
                <li class="payment-box__payment-method">
                  <Toggle
                    id="payment-type-p24"
                    v-model="paymentType"
                    value="p24"
                    :full-label="true"
                    name="payment-type"
                    type="radio"
                  >
                    Płatność online Przelewy24
                  </Toggle>
                </li>
                <li class="payment-box__payment-method">
                  <Toggle
                    id="payment-type-bank-transfer"
                    v-model="paymentType"
                    value="bankTransfer"
                    :full-label="true"
                    name="payment-type"
                    type="radio"
                  >
                    Przelew bankowy
                  </Toggle>
                </li>
                <li class="payment-box__payment-method">
                  <Toggle
                    id="payment-type-deferred"
                    v-model="paymentType"
                    value="deferred"
                    :full-label="true"
                    name="payment-type"
                    type="radio"
                  >
                    Odroczona płatność
                  </Toggle>
                  <p
                    v-if="paymentType === 'deferred'"
                    class="payment-box__vip-info"
                  >
                    Opcja dla klientów VIP. Skontaktuj się z nami na:
                    <a href="mailto:info@fotoregiony.pl">
                      info@fotoregiony.pl
                    </a>
                  </p>
                </li>
              </ul>
            </div>

            <p class="payment-box__next">
              <button
                :class="{
                  'button--loading': isProcessingOrder,
                }"
                class="button button--large button--full button--alt"
                @click="submitOnClick"
              >
                zapłać {{ cartTotalFormatted }}
              </button>
            </p>

            <div class="payment-box__checkbox-wrapper">
              <div class="toggle-checkbox">
                <Toggle
                  id="license-accept"
                  v-model="accept"
                  type="checkbox"
                  name="license-accept"
                >
                  Akceptuję
                  <router-link
                    :to="{ name: 'terms-of-use' }"
                    class="toggle-checkbox__label-link"
                    target="_blank"
                  >
                    <span>Regulamin</span>
                  </router-link>
                  oraz
                  <router-link
                    :to="{ name: 'license' }"
                    class="toggle-checkbox__label-link"
                    target="_blank"
                  >
                    <span>Umowę licencyjną</span>
                  </router-link>
                </Toggle>
              </div>
            </div>

            <router-link
              :to="{
                name: 'checkout-cart',
              }"
              class="payment-box__prev-link"
            >
              &larr;
              <span class="payment-box__prev-link-text">Wróć do koszyka</span>
            </router-link>

            <p
              v-if="isValidationMessageVisible"
              class="checkout__validation-message"
            >
              Wypełnij brakujące pola formularza
            </p>

            <p
              v-if="isLicenseAgreementMessageVisible"
              class="checkout__validation-message"
            >
              Musisz zaakceptować regulamin.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="itemsInCart === 0 && !isOrderDefined" class="row">
      <div class="col-12">
        <p class="checkout__empty">
          Koszyk jest pusty.
        </p>
      </div>
    </div>
  </CheckoutLayout>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { validationMixin } from 'vuelidate';
import {
  registerBankTransferTransaction,
  registerP24Transaction,
} from '../../payment';
import { getCart } from '../../moltin/helpers';
import CheckoutLayout from '../checkout-layout/checkout-layout.vue';
import UserAttributeFormInput from '../user-attributes-form/user-attributes-input.vue';
import Toggle from '../toggle/toggle.vue';
import Moltin from '../../moltin';

export default {
  name: 'CheckoutPayment',
  components: {
    UserAttributeFormInput,
    CheckoutLayout,
    Toggle,
  },
  mixins: [validationMixin],
  metaInfo: {
    title: 'Wybierz metodę płatności',
  },
  data: () => ({
    paymentType: 'p24', // p24 || bankTransfer
    invoice: false,
    isProcessingOrder: false,
    accept: false,
    submitTries: 0,
  }),
  asyncComputed: {
    cartTotalFormatted: {
      async get() {
        if (this.isOrderDefined) {
          const amount = (parseInt(this.$route.query.amount, 10) / 100).toFixed(
            2
          );
          return `${amount} zł`;
        }

        const cart = await getCart();
        return cart.meta.display_price.with_tax.formatted;
      },
      watch: ['productsIds'],
    },
  },
  validations: {
    accept: {
      required: value => {
        return value === true;
      },
    },
  },

  computed: {
    ...mapGetters('cart', {
      itemsInCart: 'count',
      productsIds: 'productsIds',
    }),

    ...mapGetters('categories', {
      categories: 'categories',
      loadingCategories: 'loading',
    }),

    ...mapGetters('user', {
      email: 'email',
      moltinCustomerId: 'moltinCustomerId',
    }),

    ...mapGetters('userAttributesForm', {
      userFormAttributes: 'attributes',
      $vUserForm: '$v',
    }),

    isOrderDefined() {
      return this.$route.query.amount && this.$route.query.id;
    },

    isCompanyDataDisabled() {
      return !this.invoice && this.paymentType !== 'deferred';
    },

    isAnyFieldInvalid() {
      const isUserFormInvalid = this.$vUserForm.$invalid;
      const isAcceptInvalid = this.$v.accept.$invalid;

      return isAcceptInvalid || isUserFormInvalid;
    },

    isValidationMessageVisible() {
      return this.submitTries > 0 && this.$vUserForm.$invalid;
    },

    isLicenseAgreementMessageVisible() {
      return this.submitTries > 0 && this.$v.accept.$invalid;
    },

    isInvoiceDataRequired() {
      return this.invoice || this.paymentType === 'deferred';
    },
  },

  async created() {
    this.userFormSetRequiredState({
      isInvoiceRequired: false,
      isEmailRequired: true,
    });
    await this.initUserForm();
  },

  methods: {
    ...mapActions('categories', {
      fetchCategories: 'fetchAllCategories',
    }),

    ...mapActions('userAttributesForm', {
      initUserForm: 'init',
    }),

    ...mapMutations('userAttributesForm', {
      userFormTouchAll: 'touchAll',
      userFormSetRequiredState: 'setRequiredState',
    }),

    ...mapActions('user', {
      updateUserAttributes: 'updateAttributes',
    }),

    invoiceOnChange(isInvoiceRequired) {
      this.userFormSetRequiredState({
        isInvoiceRequired,
        isEmailRequired: true,
      });
    },

    async submitOnClick() {
      if (this.isAnyFieldInvalid) {
        this.$v.$touch();
        this.userFormTouchAll();
        this.submitTries += 1;
        return;
      }

      await this.updateUserAttributes(this.userFormAttributes);
      await this.checkout();
    },

    async getOrder() {
      if (this.isOrderDefined) {
        return {
          id: this.$route.query.id,
          amount: this.$route.query.amount,
        };
      }

      const { moltin, cartId } = await Moltin();
      const order = await moltin.Cart(cartId).Checkout(this.moltinCustomerId, {
        first_name: this.userFormAttributes.firstName,
        last_name: this.userFormAttributes.lastName,
        company_name: this.userFormAttributes.companyName,
        line_1: this.userFormAttributes.street,
        city: this.userFormAttributes.city,
        postcode: this.userFormAttributes.postcode,
        phone_number: this.userFormAttributes.phoneNumber,
        instructions: `NIP: ${this.vatNumber}`,
        county: '',
        country: 'Poland',
      });

      return {
        id: order.data.id,
        amount: order.data.meta.display_price.with_tax.amount,
      };
    },

    async checkout() {
      this.isProcessingOrder = true;

      const order = await this.getOrder();

      const transactionCallbacks = {
        p24: async ({ orderId, amount, eMail }) => {
          window.location = await registerP24Transaction({
            orderId,
            amount,
            email: eMail,
          });
        },
        bankTransfer: async ({ orderId, amount, eMail }) => {
          // Authorize payment and send an e-mail to the site owner
          await registerBankTransferTransaction({
            orderId,
            amount,
            email: eMail,
            type: 'standard',
          });

          this.$router.push({
            name: 'payment-bank-transfer',
            params: {
              orderId,
            },
          });
        },
        deferred: async ({ orderId, amount, eMail }) => {
          // Authorize payment and send an e-mail to the site owner
          await registerBankTransferTransaction({
            orderId,
            amount,
            email: eMail,
            type: 'deferred',
          });

          this.$router.push({
            name: 'payment-deferred',
            params: {
              orderId,
            },
          });
        },
      };

      try {
        await transactionCallbacks[this.paymentType]({
          orderId: order.id,
          amount: order.amount,
          eMail: this.email,
        });
      } catch (e) {
        this.$bugsnag.notify(e);
        this.$router.push({
          name: 'checkout-payment-error',
        });
      } finally {
        this.isProcessingOrder = false;
      }
    },
  },
};
</script>
